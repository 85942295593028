import moment from "moment";

require('./bootstrap');

window.Vue = require('vue').default;

import '@fortawesome/fontawesome-free/css/all.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

import router from './router/router';

import '@mdi/font/css/materialdesignicons.css';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import VueMask from 'v-mask';
import store from './store/store';
import Vue from "vue";
import money from 'vuejs-money';
import VueCompositionAPI from '@vue/composition-api';

import { Chart, registerables } from "chart.js";

import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';
import 'vuetify/dist/vuetify.min.css';


const vuetify = new Vuetify()

Chart.register(...registerables);

Vue.use(Vuetify)
Vue.use(VueMask)
Vue.use(money)
Vue.use(VueCompositionAPI)

Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'md',
  })

const opts = {
    icons: {
        //iconfont: 'mdi'
        iconfont: 'md' || 'fa',
    },
    theme: {
        themes: {
            light: {
                primary: '#9F3639',
                secondary: '#9F3639',
                accent: '#E04E52',
                error: '#b71c1c',
            },
        },
    },
}


Vue.component('template-app', require('./App.vue').default);
Vue.component('infobox', require('@components/InfoBox').default);


Vue.prototype.$maskCpf = "###.###.###-##"
Vue.prototype.$maskCnpj = "##.###.###/####-##"
Vue.prototype.$maskData = "##/##/####"
Vue.prototype.$maskCep = "#####-###"
Vue.prototype.$maskTelefone = "(##) #####-####"

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});
Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY - HH:mm')
    }
});
Vue.filter('tipoServico', function(value) {
    let res = ''
    switch(value){
        case 1 : res = "Montagem"; break;
        case 2 : res = "Frete"; break;
        case 3 : res = "Frete e Montagem"; break;
    }
    return res
});
Vue.filter('tipoDescontoVenda', function(value) {
    let res = ''
    switch(value){
        case 1: res = "Com RT 10%"; break;
        case 2: res = "Frete"; break;
        case 3: res = "DESCONTO BICCATECA - NF PRODUTO + NF SERVIÇO"; break;
        case 4: res = "Com RT 5%"; break;
        case 99: res = "Especial"; break;
        case 0: res = ""; break;
        default: res = "Desconhecido"; break;
    }
    return res
});
Vue.filter('formatMoney', function(value, symbol = 'R$', digits = 2) {
    var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });

    if(symbol !== '')symbol = symbol+' ';

    return symbol + formatter.format(value);
});


const app = new Vue({
    el: '#app',
    router,
    store,
    vuetify: new Vuetify(opts)
});
