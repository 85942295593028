import Vue from 'vue'
import Vuex from 'vuex'
import staticData from '@api/staticData'

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        isLogged: false,
        user: null,
        listaEstados: [],
        optionsRelatorioOrcamentoGn: {
            itemsPerPage: '15',
            search: "",
            withValue: true,
            withEspelho: false,
        }
    },
    getters: {

    },
    mutations : {
        isLogged(state, payload){
            state.isLogged = payload
        },
        setUser(state, payload){
            state.user = payload
        },
        setListaEstados(state, payload){
            state.listaEstados = payload
        },
        setOptionsRelatorioOrcamentoGn(state, payload){
            state.optionsRelatorioOrcamentoGn = payload
        },
    },
    actions: {
        async loadStaticData({commit}){

            const listaStaticData = await staticData.getAll()
            commit('setListaEstados', listaStaticData.data.estados)

        }
    },
})

export default store
